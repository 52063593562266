import React, {
  createContext,
  PropsWithChildren,
  ReactElement,
  Suspense,
  useContext,
  useMemo,
} from "react";

import ModalProvider from "./modal";
import useGlobalModalReducer from "./store";

type GlobalModalContextType = {
  closeAll: () => void;
  closeById: (id: number) => void;
  open: (el: ReactElement, closeOther?: Boolean) => void;
};

const GlobalModalContext = createContext<GlobalModalContextType>({
  closeAll: () => {},
  closeById: () => {},
  open: () => {},
});

const GlobalModalProvider = ({
  children,
}: PropsWithChildren<{}>): JSX.Element => {
  const [state, dispatch] = useGlobalModalReducer();

  const context = useMemo<GlobalModalContextType>(
    () => ({
      closeAll: () => dispatch({ type: "close_all" }),
      closeById: (id) => dispatch({ payload: id, type: "close" }),
      open: (el, closeOther = false) => {
        dispatch({ closeOther, payload: el, type: "open" });
      },
    }),
    [dispatch],
  );

  return (
    <GlobalModalContext.Provider value={context}>
      {children}

      <Suspense>
        {state.modalList.map(({ el, id }) => (
          <ModalProvider id={id} key={id}>
            {el}
          </ModalProvider>
        ))}
      </Suspense>
    </GlobalModalContext.Provider>
  );
};

export const useGlobalModal = () => {
  return useContext(GlobalModalContext);
};

export default GlobalModalProvider;
