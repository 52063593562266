import { ApiRes } from ".";

export class ApiError extends Error {
  data?: any;
  errorText?: string;
  status: number;

  constructor(res: ApiRes<any>) {
    super();
    this.data = res.data;
    this.errorText = res.errorText;
    this.status = res.status;
  }
}
