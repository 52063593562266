import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export type MessageType = {
  message: string;
  username: string;
};

export type GroupChatStateType = {
  chatHistory: MessageType[];
};

export const groupChatSlice = createSlice({
  initialState: {} as GroupChatStateType,
  name: "groupChat",
  reducers: {
    chatHistoryAppend: (state, { payload }: PayloadAction<MessageType>) => {
      state.chatHistory = [...(state.chatHistory || []), payload];
    },
    chatHistorySet: (state, { payload }: PayloadAction<MessageType[]>) => {
      state.chatHistory = payload;
    },
  },
});

export const groupChatSelectors = {
  chatHistoryGet: (state: RootState) => state.groupChat.chatHistory || [],
};

export const groupChatActions = groupChatSlice.actions;
export default groupChatSlice.reducer;
