import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import auth from "./reducers/auth";
import broadcast from "./reducers/broadcast";
import chatContext from "./reducers/chat";
import groupChat from "./reducers/group-chat";
import matcher from "./reducers/matcher";

const store = configureStore({
  reducer: {
    auth,
    broadcast,
    chatContext,
    groupChat,
    matcher,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
