import "style/index.scss";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ApiError } from "api/error";
import Page from "components/page";
import AuthProvider from "contexts/auth/provider";
import GlobalModalProvider from "contexts/global-modal/provider";
import { SocketProvider } from "contexts/socket";
import { GlobalToastProvider } from "contexts/toast/provider";
import { StrictMode } from "react";
import { Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { routes } from "router/routes";
import store from "store";
import { registerDocSizeListener } from "style/js/doc-size";
import SocketListener from "util/socket/listener";
import { rememberUserTrackingData } from "util/user-tracking";

registerDocSizeListener();
rememberUserTrackingData();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (error instanceof ApiError && [401, 404].includes(error.status)) {
          return false;
        }
        return failureCount < 3;
      },
    },
  },
});

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <GlobalToastProvider>
        <GlobalModalProvider>
          <Suspense fallback={<Page loading />}>
            <AuthProvider>
              <StrictMode>
                <SocketProvider>
                  <RouterProvider router={router} />

                  <SocketListener />
                </SocketProvider>
              </StrictMode>
            </AuthProvider>
          </Suspense>
        </GlobalModalProvider>
      </GlobalToastProvider>
    </Provider>
  </QueryClientProvider>,
);
