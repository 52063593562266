import clsx from "clsx";
import React, { PropsWithChildren } from "react";
import useMetaTags from "react-metatags-hook";
import { MetaTagsConfig } from "react-metatags-hook/dist/types";

import css from "./index.module.scss";

type Props = {
  centerVertically?: boolean;
  loading?: boolean;
  maxWidth?: string | number;
  seo?: MetaTagsConfig;
  topLayer?: boolean;
};

const Page = ({
  centerVertically = false,
  children,
  loading = false,
  maxWidth = "100%",
  seo = {},
  topLayer = false,
}: PropsWithChildren<Props>) => {
  useMetaTags(seo);

  return (
    <div
      className={clsx(
        css.wrapper,
        (centerVertically || loading) && css.centerVertically,
        topLayer && css.topLayer,
      )}
    >
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div className={css.content} style={{ maxWidth }}>
          {children}
        </div>
      )}
    </div>
  );
};

export default Page;
