import useAuthCurrent from "api/auth/use-auth-current";
import Page from "components/page";
import PageError from "components/page-error";
import { useToasts } from "contexts/toast/provider";
import { usePrevious } from "hooks/common/use-previous";
import useMatcherResendData from "hooks/matcher/use-resend-data";
import React, { PropsWithChildren, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store";
import { authActions, authSelectors } from "store/reducers/auth";

const AuthProvider = ({ children }: PropsWithChildren<{}>): JSX.Element => {
  const authLoaded = useAppSelector(authSelectors.getLoaded);
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(authSelectors.getIsLoggedIn);
  const matcherResendData = useMatcherResendData();
  const { addToast } = useToasts();
  const { data, error, isLoading } = useAuthCurrent();

  const authWasLoaded = usePrevious(authLoaded);

  useEffect(() => {
    if (!data) return;

    dispatch(authActions.setCsrf(data.csrf));
    dispatch(authActions.setLoaded(true));
    dispatch(authActions.setUser(data.user));
  }, [data, dispatch]);

  useEffect(() => {
    if (!authLoaded || !authWasLoaded || !isLoggedIn) {
      return;
    }

    matcherResendData();
  }, [authLoaded, authWasLoaded, isLoggedIn, matcherResendData]);

  useEffect(() => {
    if (!authLoaded || !isLoggedIn) {
      return;
    }

    addToast("Welcome!", { appearance: "success" });
  }, [addToast, authLoaded, isLoggedIn]);

  if (error) {
    return <PageError />;
  }

  if (isLoading || !authLoaded) {
    return <Page loading />;
  }

  return <>{children}</>;
};

export default AuthProvider;
