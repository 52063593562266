import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export type MatcherMatchType = {
  advertId: string;
  broadcastServer: string;
  broadcastUsername: string;
  gender: string;
  id: number;
  ip: string;
  isModel: boolean;
  username: string;
};

export type MatcherStateType = {
  filterGenderList: string[];
  isBanned: boolean;
  match?: MatcherMatchType;
};

const matcherSlice = createSlice({
  initialState: {
    filterGenderList: ["m", "f"],
  } as MatcherStateType,
  name: "matcher",
  reducers: {
    setFilterGenderList: (state, { payload }: PayloadAction<string[]>) => ({
      ...state,
      filterGenderList: payload,
    }),
    setIsBanned: (state, { payload }: PayloadAction<boolean>) => ({
      ...state,
      isBanned: payload,
    }),
    setMatch: (
      state,
      { payload }: PayloadAction<MatcherMatchType | undefined>,
    ) => ({
      ...state,
      match: payload,
    }),
  },
});

export const matcherActions = matcherSlice.actions;

export const matcherSelectors = {
  getFilterGenderList: (state: RootState) => state.matcher.filterGenderList,
  getHasMatch: (state: RootState) => !!state.matcher.match,
  getIsBanned: (state: RootState) => state.matcher.isBanned === true,
  getMatch: (state: RootState) => state.matcher.match,
  getUserGender: (state: RootState) =>
    state.auth.user?.gender === "f" ? "f" : "m",
};

export default matcherSlice.reducer;
