import useApi from "api";
import { useEffect, useState } from "react";

import { WrappedSocket } from "./base";

type Props = {
  lobbySocket: WrappedSocket;
};

const SocketConnectionCheck = ({ lobbySocket }: Props) => {
  const [isRestarting, setIsRestarting] = useState(false);
  const api = useApi();

  useEffect(() => {
    let timeout: number;

    lobbySocket.on("v3.lobby.restarting", () => {
      setIsRestarting(true);
      timeout = window.setTimeout(() => {
        setIsRestarting(false);
      }, 30 * 1000);
    });

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [lobbySocket]);

  useEffect(() => {
    if (isRestarting) return;

    let isLogSent = false;
    let timeout: number | undefined;

    const checkConnectedWithTimeout = () => {
      if (timeout) return;

      timeout = window.setTimeout(async () => {
        timeout = undefined;
        if (lobbySocket.connected || isLogSent) return;

        try {
          await api("api/v1/log/frontend-ws-fail", {
            method: "post",
          });
          console.log("[socket-connection-check] log sent"); // eslint-disable-line no-console
          isLogSent = true;
        } catch (e) {
          console.log("[socket-connection-check] log not sent error", e); // eslint-disable-line no-console
        }
      }, 10000);
    };

    checkConnectedWithTimeout();

    const disconnectHandler = () => {
      console.log("[socket-connection-check] disconnection detected", timeout); // eslint-disable-line no-console
      checkConnectedWithTimeout();
    };

    lobbySocket.on("_disconnect", disconnectHandler);

    return () => {
      lobbySocket.off("_disconnect", disconnectHandler);
      if (timeout) clearTimeout(timeout);
    };
  }, [api, isRestarting, lobbySocket]);

  return null;
};

export default SocketConnectionCheck;
