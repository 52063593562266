import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserType } from "api/auth/use-auth-current";
import { RootState } from "store";

export type AuthStateType = {
  csrf: string;
  loaded?: boolean;
  user?: UserType;
};

export const authSlice = createSlice({
  initialState: {} as AuthStateType,
  name: "auth",
  reducers: {
    setCsrf: (state, { payload }: PayloadAction<string>) => {
      state.csrf = payload;
    },
    setLoaded: (state, { payload }: PayloadAction<boolean>) => {
      state.loaded = payload;
    },
    setUser: (state, { payload }: PayloadAction<UserType | undefined>) => {
      state.user = payload;
    },
    updateUserTokens: (state, { payload }) => {
      if (!state.user) return;
      state.user.tokens = payload;
    },
    updateUserGender: (state, { payload }: PayloadAction<string>) => {
      if (state.user) {
        state.user.gender = payload;
      }
    },
  },
});

export const authSelectors = {
  getCsrf: (state: RootState) => state.auth.csrf,
  getGeoIsoCode: (state: RootState) => state.auth.user?.geo.isoCode,
  getHasCC: (state: RootState) => state.auth.user?.has_cc === true,
  getIsLoggedIn: (state: RootState) =>
    state.auth.user && !state.auth.user.username.startsWith("guest_"),
  getLoaded: (state: RootState) => state.auth.loaded === true,
  getTokens: (state: RootState) => state.auth.user?.tokens,
  getUsername: (state: RootState) => state.auth.user?.username,
  getUserNodeToken: (state: RootState) => state.auth.user?.node_token,
};

export const authActions = authSlice.actions;
export default authSlice.reducer;
