import { ReactElement, Reducer, useReducer } from "react";

type ActionType = {
  closeOther?: Boolean;
  payload?: ReactElement | number;
  type: "close" | "close_all" | "open";
};

type StoreType = {
  modalList: { el: ReactElement; id: number }[];
  nextId: number;
};

const INITIAL_STATE: StoreType = { modalList: [], nextId: 0 };

const reducer: Reducer<StoreType, ActionType> = (
  prevState,
  { closeOther, payload, type },
) => {
  if (type === "close" && typeof payload === "number") {
    return {
      modalList: prevState.modalList.filter((item) => payload !== item.id),
      nextId: prevState.nextId,
    };
  }

  if (type === "close_all") {
    return {
      modalList: [],
      nextId: prevState.nextId,
    };
  }

  if (type === "open" && typeof payload === "object") {
    const modalListNew = closeOther
      ? [{ el: payload, id: prevState.nextId }]
      : [...prevState.modalList, { el: payload, id: prevState.nextId }];

    return {
      modalList: modalListNew,
      nextId: prevState.nextId + 1,
    };
  }

  return prevState;
};

const useGlobalModalReducer = () => {
  return useReducer(reducer, INITIAL_STATE);
};

export default useGlobalModalReducer;
