import { useLobbySocket } from "contexts/socket";
import { useEffect } from "react";
import { useAppDispatch } from "store";
import { authActions } from "store/reducers/auth";

type TokenUpdateDataType = {
  tokens?: number;
};

const SocketListener = () => {
  const dispatch = useAppDispatch();
  const lobbySocket = useLobbySocket();

  useEffect(() => {
    if (!lobbySocket) return;

    const onTokenUpdate = (data: TokenUpdateDataType) => {
      if (data.tokens === undefined) return;
      dispatch(authActions.updateUserTokens(data.tokens));
    };

    lobbySocket.on("v3.token.update", onTokenUpdate);

    return () => {
      lobbySocket.off("v3.token.update", onTokenUpdate);
    };
  }, [dispatch, lobbySocket]);

  return null;
};

export default SocketListener;
