import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";

export type BroadcastStateType = {
  advertId?: string;
  server?: string;
  username?: string;
  videoPlayToken?: string;
};

const broadcastSlice = createSlice({
  initialState: {} as BroadcastStateType,
  name: "broadcast",
  reducers: {
    setAdvertId: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      advertId: payload,
    }),
    setServer: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      server: payload,
    }),
    setUsername: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      username: payload,
    }),
    setVideoPlayToken: (state, { payload }: PayloadAction<string>) => ({
      ...state,
      videoPlayToken: payload,
    }),
  },
});

export const broadcastSelectors = {
  getAdvertId: (state: RootState) => state.broadcast.advertId,
  getServer: (state: RootState) => state.broadcast.server,
  getUsername: (state: RootState) => state.broadcast.username,
  getVideoPlayToken: (state: RootState) => state.broadcast.videoPlayToken,
};

export const broadcastActions = broadcastSlice.actions;
export default broadcastSlice.reducer;
