import socketFactory from "./base";

export default function LobbySocket() {
  const NODE_HOST = process.env.NODE_HOST || "node2-ord.livemediahost.com";
  const NODE_PORT = process.env.NODE_PORT || 3000;

  const server = `wss://${NODE_HOST}:${NODE_PORT}/`;
  const wrapped = socketFactory(server);

  // Add some reconnect logic (just for lobby socket)
  // Little bit of random (spread out reconnects slightly...)
  let reconnectDelay = Math.floor(Math.random() * 250 + 50);

  wrapped.on("connect", () => {
    reconnectDelay = Math.floor(Math.random() * 250 + 50);
  });

  wrapped.on("_disconnect", () => {
    reconnectDelay = Math.min(60000, reconnectDelay * 2);
    setTimeout(() => {
      wrapped.reconnect();
    }, reconnectDelay);
  });

  return wrapped;
}
