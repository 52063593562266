import { useCallback } from "react";
import { useAppSelector } from "store";
import { authSelectors } from "store/reducers/auth";

import { ApiError } from "./error";

export type ApiRes<T> = {
  data: T;
  errorText?: string;
  status: number;
};

type CallbackType = <T = any>(
  url: string,
  params?: RequestInit & { data?: any },
) => Promise<ApiRes<T>>;

const useApi: () => CallbackType = () => {
  const csrf = useAppSelector(authSelectors.getCsrf);

  return useCallback<CallbackType>(
    async (url, params = {}) => {
      const { body, data, headers, ...allParams } = params;

      const fetchRes = await fetch(`/${url}`, {
        ...allParams,
        body: data ? JSON.stringify(data) : body,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          ...(csrf ? { "X-CSRF-TOKEN": csrf } : {}),
          ...headers,
        },
      });

      const isResJson = fetchRes.headers
        .get("Content-Type")
        ?.includes("application/json");

      const fetchResData = isResJson
        ? await fetchRes.json()
        : await fetchRes.text();

      const res = {
        data: fetchResData,
        errorText: (!fetchRes.ok && fetchResData?.error) || undefined,
        status: fetchRes.status,
      };

      if (res.status === 401) {
        // logout(); todo: handle logout
      }

      if (![200, 204].includes(res.status)) {
        throw new ApiError(res);
      }

      return res;
    },
    [csrf],
  );
};

export default useApi;
