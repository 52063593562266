import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useReducer,
} from "react";

import GlobalToastItem, { Options } from "./global-toast-item";
import css from "./provider.module.scss";

type GlobalToastContextType = {
  addToast: (
    content: React.ReactNode,
    options?: Options,
    onClose?: () => void,
  ) => any;
  closeById: (id: string) => any;
  removeAllToasts: () => any;
};

export const GlobalToastContext = createContext<GlobalToastContextType>(
  undefined as any,
);

type ToastPayload = {
  options?: Object;
  content?: React.ReactNode | string;
  id?: string;
  onClose?: () => void;
};

type GlobalToastState = {
  nextId: number;
  toastList: { [key: string]: ToastPayload };
};

const globaToastInitialState = {
  nextId: 0,
  toastList: {},
};

const globalToastReducer = (
  state: GlobalToastState,
  { payload, type }: { payload?: ToastPayload; type: string },
) => {
  if (type === "global_toast_open") {
    return {
      nextId: state.nextId + 1,
      toastList: {
        ...state.toastList,
        [`global_toast_${state.nextId}`]: {
          content: payload?.content,
          onClose: payload?.onClose,
          options: payload?.options,
        },
      },
    };
  }

  if (type === "global_toast_close") {
    const newToastList: { [key: string]: any } = {};
    Object.keys(state.toastList).forEach((key) => {
      if (key === payload?.id) {
        if (typeof state.toastList[key].onClose === "function") {
          state.toastList[key]?.onClose?.();
        }
      } else {
        newToastList[key] = state.toastList[key];
      }
    });
    return {
      nextId: state.nextId,
      toastList: newToastList,
    };
  }

  if (type === "global_toast_close_all") {
    return {
      nextId: state.nextId,
      toastList: {},
    };
  }

  throw new Error(`Invalid action.type "${type}" on globalToastReducer.`);
};

export const GlobalToastProvider = ({ children }: PropsWithChildren<{}>) => {
  const [state, dispatch] = useReducer(
    globalToastReducer,
    globaToastInitialState,
  );

  const context: GlobalToastContextType = useMemo(() => {
    return {
      addToast: (content: React.ReactNode, options, onClose) =>
        dispatch({
          payload: { content, onClose, options },
          type: "global_toast_open",
        }),

      closeById: (id: string) =>
        dispatch({
          payload: { id },
          type: "global_toast_close",
        }),

      removeAllToasts: () =>
        dispatch({
          payload: undefined,
          type: "global_toast_close_all",
        }),
    };
  }, [dispatch]);

  return (
    <GlobalToastContext.Provider value={context}>
      {children}

      <div className={css.wrapper}>
        {Object.keys(state.toastList).map((id) => (
          <GlobalToastItem
            content={state.toastList[id].content}
            globalToastId={id}
            key={id}
            options={state.toastList[id].options}
          />
        ))}
      </div>
    </GlobalToastContext.Provider>
  );
};

export const useToasts = () => {
  return useContext(GlobalToastContext);
};
