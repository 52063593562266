import storage from "./browser-storage";

const LS_TRACKING_DATA_KEY = "user_tracking_data";

type TrackingDataType = Record<string, string>;

// intended to execute only on init
const currentRequestAdvertData: Record<string, string> = (() => {
  // this might fail on old browsers?
  let url: URL | null = null;
  try {
    url = new URL(window.location.href);
  } catch {
    return {};
  }

  // advert_id, id, wid are the same thing.
  // wid is more important than id is more important than advert_id
  const params = [
    "advert_id",
    "id",
    "wid",
    //order of above 3 items is important for precedence
    "aclid",
    "gclid",
    "vclid",
    "ad_id",
    "cmp",
    "custom_thumb",
    "custom_tour",
    "model",
    "t",
    "type",
    "u1",
    "u2",
    "u3",
    "u4",
  ];

  const res: Record<string, string> = {};

  const prg = url?.searchParams?.get("prg");
  params.forEach((key) => {
    let value = url?.searchParams?.get(key);
    if (!value) return;

    if (key === "id" && value && prg && 1 < parseInt(prg)) {
      value = prg + "_" + value;
    }

    if (["id", "wid"].indexOf(key) !== -1) {
      key = "advert_id";
    } else if (key === "t") {
      key = "tour";
    }

    // don't allow explicit use of googref/googref2 affiliate id as it rewards 10 free tokens
    if (key === "advert_id" && ["googref", "googref2"].indexOf(value) !== -1) {
      value = "noadvert";
    }

    res[key] = value;
  });

  return res;
})();

export const getUserTrackingDataAll = (): TrackingDataType => {
  try {
    let res =
      JSON.parse(storage.local.getItem(LS_TRACKING_DATA_KEY) || "") || {};
    if (
      res?.created_at &&
      parseInt(res.created_at) < Date.now() - 1000 * 60 * 60
    ) {
      storage.local.removeItem(LS_TRACKING_DATA_KEY);
      res = {};
    }
    return res;
  } catch {
    return {};
  }
};

// export const getUserTrackingDataKey = (key: string): string | undefined => {
//   return getUserTrackingDataAll()?.[key];
// };

// export const setUserTrackingDataKey = (key: string, value: string) => {
//   try {
//     const data = getUserTrackingDataAll();
//     data[key] = value;
//     storage.local.setItem(LS_TRACKING_DATA_KEY, JSON.stringify(data));
//   } catch {}
// };

export const rememberUserTrackingData = () => {
  const userTrackingData: TrackingDataType = {
    advert_id: "noadvert",
    tour: "1",
    ...getUserTrackingDataAll(),
    ...currentRequestAdvertData,
  };

  if (Object.keys(currentRequestAdvertData).length) {
    userTrackingData.landing_page = window.location.href;
    userTrackingData.referrer = document.referrer;
  }

  userTrackingData.created_at = Date.now().toString();

  try {
    storage.local.setItem(
      LS_TRACKING_DATA_KEY,
      JSON.stringify(userTrackingData),
    );
  } catch {}
};

export const getTrackingPixelUrl = (
  webmasterUsername: string | undefined,
  sitecode: string | undefined,
) => {
  try {
    const associate = {
      a: "advert_id",
      ad: "ad_id",
      cmp: "cmp",
      custom_aclid: "aclid",
      custom_gclid: "gclid",
      custom_tour: "custom_tour",
      custom_vclid: "vclid",
      r: "referrer",
      s: "sitecode",
      t: "tour",
      u1: "u1",
      u2: "u2",
      u3: "u3",
      u4: "u4",
    };

    const searchParams = new URLSearchParams();

    Object.entries(associate).forEach(([key, value]) => {
      let res = currentRequestAdvertData[value];

      if (key === "a") {
        res =
          webmasterUsername || currentRequestAdvertData.advert_id || "noadvert";
      } else if (key === "s" && sitecode) {
        res = sitecode;
      } else if (key === "t") {
        res = value || "1";
      } else if (key === "r") {
        res = document.referrer;
      }

      searchParams.append(key, res || "");
    });

    searchParams.append("p", "main");

    if (currentRequestAdvertData.type) {
      searchParams.append("prg", currentRequestAdvertData.type);
    }

    return "//secure-track1.com/ct/imgcount.php?" + searchParams.toString();
  } catch (error) {
    return "";
  }
};
