import { useQuery } from "@tanstack/react-query";
import useApi from "api";

export type UserType = {
  gender: string;
  geo: {
    isoCode: string;
  };
  has_cc: boolean;
  node_token: string;
  tokens: number;
  username: string;
};

type AuthCurrentResponse = {
  csrf: string;
  user: UserType;
};

const useAuthCurrent = () => {
  const api = useApi();

  return useQuery<AuthCurrentResponse>(
    ["auth"],
    async () => (await api("api/v1/user/current")).data,
  );
};

export default useAuthCurrent;
