import EventEmitter from "eventemitter3";
import * as proto from "nodejs/proto.js";

type SocketLookupType = Record<string, WrappedSocket | null>;

let sockets = {} as SocketLookupType;

export class WrappedSocket extends EventEmitter {
  authorized: boolean;
  connected: boolean;
  connecting: boolean;
  decoder: proto.Decoder;
  reconnecting: string | number | undefined;
  server: string;
  socket: WebSocket;

  constructor(socket: WebSocket, server: string) {
    super();
    this.socket = socket;
    this.server = server;
    this.authorized = false;
    this.connected = false;
    this.connecting = true;
    this.reconnecting = undefined;

    this.decoder = new proto.Decoder((event: string, data: string) => {
      // console.log("Decoded '"+event+"' packet, data: ");
      // console.dir(data);
      if (event === "v3.templates") {
        this.decoder.updateTemplates(data);
      } else {
        this.emit(event, data);
      }
    });

    this.on("v3.ping", (data: string) => {
      this.send("v3.pong");
    });

    this.on("v3.authorize.success", (data) => {
      // console.log('WEBSOCKET.v3.authorize.success', this.server);
      this.authorized = true;
    });

    this.addHandlers();
  }

  authenticate(data: { token: string; version?: string }) {
    if (!this.connected) return;
    if (this.authorized) return;
    this.send("v3.authorize", data);
  }

  reconnect(delay = 0) {
    const doReconnect = () => {
      this.removeHandlersAndClose(this.socket);

      this.authorized = false;
      this.connected = false;
      this.connecting = true;
      this.reconnecting = undefined;
      this.socket = new WebSocket(this.server);
      this.socket.binaryType = "arraybuffer";
      this.addHandlers();
    };

    if (delay) {
      if (this.reconnecting) {
        clearTimeout(this.reconnecting);
      }
      this.reconnecting = window.setTimeout(doReconnect, delay);
    } else {
      doReconnect();
    }
  }

  addHandlers() {
    this.socket.onopen = () => {
      // console.log('WEBSOCKET.onopen', this.server);
      this.connected = true;
      this.connecting = false;
      this.emit("connect");
    };

    this.socket.onmessage = (event) => {
      // console.log('WEBSOCKET.onmessage:', this.server);
      // console.dir(event);
      this.decoder.onMessage(event.data);
    };

    this.socket.onerror = (error) => {
      // console.log('baseSocket socket error!', this.server);
    };

    this.socket.onclose = (reason) => {
      // console.log('WEBSOCKET.onclose:', this.server);
      // console.dir(reason);
      this.connecting = false;
      this.emit("_disconnect");
      if (this.connected) {
        this.connected = false;
        sockets[this.server] = null;
        this.emit("disconnect");
      }
    };
  }

  removeHandlersAndClose(socket: WebSocket) {
    socket.onopen = () => {};
    socket.onmessage = () => {};
    socket.onerror = () => {};
    socket.onclose = () => {};
    socket.close();
  }

  getRawSocket() {
    return this.socket;
  }

  isAuthorized() {
    return this.authorized;
  }

  isConnected() {
    return this.connected && !this.reconnecting;
  }

  close() {
    this.socket.close();
  }

  send(ev: string, data?: number | string | object) {
    if (!this.connected) return;
    let packet = proto.encode(ev, data);
    this.socket.send(packet);
  }

  release() {
    this.socket.close();
    sockets[this.server] = null;
  }
}

function makeWebSocket(server: string) {
  // console.log('makeWebSocket: ' + server);
  const socket = new WebSocket(server);
  socket.binaryType = "arraybuffer";

  return new WrappedSocket(socket, server);
}

export default function socketFactory(server: string) {
  if (!sockets[server]) {
    // console.log('need to create a new socket', server);
    sockets[server] = makeWebSocket(server);
  }

  return sockets[server]!;
}
