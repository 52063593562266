/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useToasts } from "contexts/toast/provider";
import React, { useEffect, useMemo } from "react";

import css from "./global-toast-item.module.scss";

const AUTO_DISMISS_MS = 2500;

export type Options = {
  appearance: "success" | "error" | "info";
  autoDismiss?: boolean;
  title?: React.ReactNode;
};

type Props = {
  content: React.ReactNode;
  options: Options;
  globalToastId: string;
};

const GlobalToastItem = ({
  content,
  options = { appearance: "success" },
  globalToastId,
}: Props) => {
  const { appearance, autoDismiss = true, title } = options;
  const { closeById } = useToasts();

  useEffect(() => {
    if (!autoDismiss) return;
    setTimeout(() => closeById(globalToastId), AUTO_DISMISS_MS);
  }, [autoDismiss, closeById, globalToastId]);

  return useMemo(() => {
    const close = () => closeById(globalToastId);

    return (
      <div
        className={`${css.toast} ${css[appearance]}`}
        onClick={close}
        onKeyPress={close}
      >
        <strong className={css.title}>
          {title ||
            appearance.charAt(0).toUpperCase() +
              appearance.slice(1).toLowerCase()}
        </strong>
        <span className={css.text}>{content}</span>
      </div>
    );
  }, [appearance, closeById, content, globalToastId, title]);
};

export default GlobalToastItem;
