import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { ChatModeType } from "types/chat/mode";
import { ChatStatusType } from "types/chat/status";

export type ChatStateType = {
  auth?: string;
  chat?: ChatChatStateType;
  error?: ChatErrorStateType;
  isLoading: boolean;
  mode: ChatModeType;
  stream?: ChatStreamStateType;
  username?: string;
};

type ChatChatStateType = {
  status: ChatStatusType;
};

type ChatErrorStateType = {
  code: number;
  message: string;
};

type ChatStreamStateType = {
  edge_servers: string[];
  ffmpeg_server?: string;
  stream_name?: string;
  token?: string;
  username?: string;
};

const initialState: ChatStateType = {
  isLoading: true,
  mode: "public",
};

const chatContextSlice = createSlice({
  initialState,

  name: "chatContext",

  reducers: {
    clearStore: () => initialState,
    clearStream: (state) => {
      state.stream = {
        ...state.stream,
        edge_servers: [],
        ffmpeg_server: undefined,
        stream_name: undefined,
        token: undefined,
      };
    },
    setDataFromFetch: (state, { payload }: PayloadAction<ChatStateType>) => {
      state.error = undefined;
      state.isLoading = false;
      state.username = payload.username;

      state.auth = payload.auth;
      state.chat = payload.chat;
      state.mode = payload.mode;

      if (payload.stream) {
        state.stream = {
          ...payload.stream,
          username: payload.username,
        };
      }
    },
    setError: (state, { payload }: PayloadAction<ChatErrorStateType>) => {
      state.error = payload;
      state.isLoading = false;
    },
    setLoading: (state, { payload }: PayloadAction<{ username?: string }>) => {
      state.error = undefined;
      state.isLoading = true;
      state.username = payload.username;
    },
    setMode: (state, { payload }: PayloadAction<ChatModeType>) => {
      state.mode = payload;
    },
    setStream: (state, { payload }: PayloadAction<ChatStreamStateType>) => {
      state.stream = payload;
    },
    updateChat: (state, { payload }: PayloadAction<ChatChatStateType>) => {
      state.chat = {
        ...state.chat,
        ...payload,
      };
    },
  },
});

export const chatContextActions = chatContextSlice.actions;

export const chatContextSelectors = {
  getChatStatus: (state: RootState) =>
    state.chatContext.chat?.status || "offline",
  getError: (state: RootState) => state.chatContext.error,
  getFfmpegServer: (state: RootState) =>
    state.chatContext.stream?.ffmpeg_server || null,
  getIsLoading: (state: RootState) => state.chatContext.isLoading,
  getMode: (state: RootState) => state.chatContext.mode,
  getUsername: (state: RootState) => state.chatContext.username,
};

export default chatContextSlice.reducer;
