import React, {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
} from "react";

import { useGlobalModal } from "./provider";

type ModalContextType = {
  close: () => void;
};

type Props = {
  id: number;
};

const ModalContext = createContext<ModalContextType>({
  close: () => {},
});

const ModalProvider = ({
  children,
  id,
}: PropsWithChildren<Props>): JSX.Element => {
  const { closeById } = useGlobalModal();

  const context = useMemo<ModalContextType>(
    () => ({
      close: () => closeById(id),
    }),
    [closeById, id],
  );

  return (
    <ModalContext.Provider value={context}>{children}</ModalContext.Provider>
  );
};

export const useCloseModal = () => {
  const { close } = useContext(ModalContext);

  return close;
};

export default ModalProvider;
