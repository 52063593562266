import Page from "components/page";
import React, { PropsWithChildren } from "react";

import css from "./index.module.scss";

type Props = {
  errorString?: string;
};

const PageError = ({
  children,
  errorString = "An error occurred...",
}: PropsWithChildren<Props>) => {
  return (
    <Page centerVertically={true} topLayer={true}>
      <div className={css.body}>
        <h3 className={css.title}>{errorString}</h3>
        {children}
      </div>
    </Page>
  );
};

export default PageError;
