import { useCompare } from "hooks/common/use-compare";
import { useEffect, useRef } from "react";
import { usePageVisibility } from "react-page-visibility";

import { WrappedSocket } from "./base";

const socketReconnectAfterMs = 1000 * 60 * 5;

type Props = {
  socket: WrappedSocket;
};

const SocketReconnectAfterBackground = ({ socket }: Props) => {
  const isPageVisible = usePageVisibility();
  const lastPageVisibleTime = useRef<number | null>(null);

  const hasPageVisibleChanged = useCompare(isPageVisible);

  // Fix losing socket connection after being backgrounded for a while
  // websockets can't always be relied upon to close themselves correctly
  useEffect(() => {
    if (!hasPageVisibleChanged) {
      return;
    }

    if (!isPageVisible) {
      lastPageVisibleTime.current = Date.now();
    } else {
      if (
        lastPageVisibleTime.current &&
        lastPageVisibleTime.current < Date.now() - socketReconnectAfterMs
      ) {
        socket.reconnect(1500);
      }
    }
  }, [hasPageVisibleChanged, isPageVisible, socket]);

  return null;
};

export default SocketReconnectAfterBackground;
