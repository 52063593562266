import useApi from "api";
import { useCallback } from "react";
import { useAppSelector } from "store";
import { broadcastSelectors } from "store/reducers/broadcast";
import { matcherSelectors } from "store/reducers/matcher";

const useMatcherResendData = () => {
  const api = useApi();
  const broadcastAdvertId = useAppSelector(broadcastSelectors.getAdvertId);
  const broadcastServer = useAppSelector(broadcastSelectors.getServer);
  const match = useAppSelector(matcherSelectors.getMatch);
  const userGender = useAppSelector(matcherSelectors.getUserGender);

  return useCallback(async () => {
    await api("api/v1/tikichat/data", {
      data: {
        advertId: broadcastAdvertId,
        broadcastServer: broadcastServer,
        gender: userGender,
        match: match,
      },
      method: "post",
    });
  }, [api, broadcastAdvertId, broadcastServer, match, userGender]);
};

export default useMatcherResendData;
