let templates = require("./proto/templates.js");
let events = require("./proto/events.js");

module.exports.encode = function (event, data) {
  // Munge data to get rid of common templates
  let tpl = applyTemplate(event, data);
  if (tpl) {
    var tpl_id = tpl[0];
    var tpl_data = tpl[1];
    data = tpl[2];
  }

  // Assemble packet [id/event, untemplated_data, opt template_id, opt templated_data]
  let packet = [events.getId(event), data];
  if (tpl_id > 0) {
    packet.push(tpl_id);
    packet.push(tpl_data);
  }

  return JSON.stringify(packet);
};

function Decoder(cb) {
  this.cb = cb;
}

Decoder.prototype.onMessage = function (message) {
  try {
    let packet = JSON.parse(message);

    let event = events.getName(packet[0]);
    let data = packet[1];

    // Was it a templated packet?
    if (packet.length > 2) {
      let tpl_id = packet[2];
      let tpl_data = packet[3];
      data = parseTemplate(event, data, tpl_id, tpl_data);
    }

    this.cb(event, data);
  } catch (err) {
    // FIXME, these were suppressed?
    /*
    console.log(err);
    console.dir(err);
    console.dir(message);
    */
  }
};

Decoder.prototype.updateTemplates = function (data) {
  templates = data.templates;
  events.setEvents(data.events);
};

module.exports.Decoder = Decoder;

function parseTemplate(event, data, tpl_id, tpl_data) {
  let tpl = templates[event];
  if (tpl) {
    let tpl_len = tpl.length;
    for (let field_id = 0; field_id < tpl_len; field_id++) {
      if (tpl_id & (1 << field_id)) {
        let field = tpl[field_id];
        if (typeof field == "string") {
          data[field] = tpl_data.shift();
        } else {
          data[field.key] = parseSubTemplate(field, tpl_data.shift());
        }
      }
    }
  }

  return data;
}

function parseSubTemplate(sub_tpl, data) {
  if (!sub_tpl || !data) return;

  if (sub_tpl.type === "object") {
    return parseSingleObject(sub_tpl.fields, data);
  } else if (sub_tpl.type === "array") {
    let result = [];
    let len = data.length;
    for (let i = 0; i < len; i++) {
      result.push(parseSingleObject(sub_tpl.fields, data[i]));
    }
    return result;
  }
}

function parseSingleObject(fields, data) {
  let mask = data[0];
  let templated = data[1];
  let extras = data.length > 2 ? data[2] : {};

  let result = extras;
  let len = fields.length;
  for (let field_id = 0; field_id < len; field_id++) {
    if (mask & (1 << field_id)) {
      let field = fields[field_id];
      result[field] = templated.shift();
    }
  }

  return result;
}

function applyTemplate(event, data) {
  if (!templates[event] || typeof data !== "object") return false;

  //console.log("Applying for event: "+event);
  let tpl_id = 0;
  let templated = [];
  let extras = {};
  let templated_fields = {};
  let fields = templates[event];
  let num_fields = fields.length;
  for (let field_id = 0; field_id < num_fields; field_id++) {
    let field = fields[field_id];

    if (typeof field === "string" && data[field] !== undefined) {
      // Primitive template?
      tpl_id = tpl_id | (1 << field_id);
      templated.push(data[field]);
      templated_fields[field] = 1;
    } else if (typeof field !== "string" && data[field.key] !== undefined) {
      // advanced types get subtemplated
      tpl_id = tpl_id | (1 << field_id);
      templated.push(buildSubTemplate(field, data[field.key]));
      templated_fields[field.key] = 1;
    }
  }

  if (tpl_id > 0) {
    let keys = Object.keys(data);
    for (let x = 0; x < keys.length; x++) {
      let key = keys[x];
      if (!templated_fields[key]) {
        extras[key] = data[key];
      }
    }
  } else {
    extras = data;
  }

  return [tpl_id, templated, extras];
}

// Basically the same as above (needs redone I think?)
function buildSubTemplate(subtemplate, data) {
  //console.log("template type: "+subtemplate.type+", data type: "+(typeof data)+" toString: "+data.toString());
  if (subtemplate.type === "object" && data.constructor === Object) {
    return templateSingleObject(subtemplate.fields, data);
  } else if (subtemplate.type === "array" && data.constructor === Array) {
    let result = [];
    let data_length = data.length;
    for (let x = 0; x < data_length; x++) {
      result.push(templateSingleObject(subtemplate.fields, data[x]));
    }
    return result;
  }
}

function templateSingleObject(fields, data) {
  if (typeof data !== "object") return data;

  let mask = 0;
  let extras = {};
  let templated_data = [];
  let templated_fields = {};
  let has_extras = false;

  let length = fields.length;
  for (let field_id = 0; field_id < length; field_id++) {
    let field = fields[field_id];
    //console.log("Checking field: "+field);
    if (data[field] !== undefined) {
      //console.log("It exists");
      mask |= 1 << field_id;
      templated_data.push(data[field]);
      templated_fields[field] = 1;
    }
  }

  // Has to work like this (on a copy) so we dont edit data someone else is holding
  if (mask > 0) {
    let keys = Object.keys(data);
    for (let x = 0; x < keys.length; x++) {
      let key = keys[x];
      if (!templated_fields[key]) {
        extras[key] = data[key];
        has_extras = true;
      }
    }
  } else {
    extras = data;
    has_extras = true;
  }

  if (has_extras) {
    return [mask, templated_data, extras];
  } else {
    return [mask, templated_data];
  }
}
