import React from "react";
import { createContext, PropsWithChildren, useContext } from "react";
import { useEffect } from "react";
import { useAppSelector } from "store";
import { authSelectors } from "store/reducers/auth";
import { WrappedSocket } from "util/socket/base";
import SocketConnectionCheck from "util/socket/connection-check";
import LobbySocket from "util/socket/lobby";
import SocketReconnectAfterBackground from "util/socket/reconnect-after-background";

type SocketContextType = {
  lobbySocket: WrappedSocket;
};

const lobbySocket = LobbySocket();

const SocketContext = createContext<SocketContextType>({ lobbySocket });

const SocketProvider = ({ children }: PropsWithChildren<{}>) => {
  const authUserNodeToken = useAppSelector(authSelectors.getUserNodeToken);

  // When authUser changes, we can connect/auth.
  useEffect(() => {
    if (!authUserNodeToken) {
      return;
    }

    const doAuthenticate = () => {
      lobbySocket.authenticate({
        token: authUserNodeToken,
      });
    };

    if (lobbySocket.authorized) {
      lobbySocket.reconnect();
    } else if (lobbySocket.connected) {
      doAuthenticate();
    }

    lobbySocket.on("connect", doAuthenticate);

    return () => {
      lobbySocket.off("connect", doAuthenticate);
    };
  }, [authUserNodeToken]);

  if (!authUserNodeToken) {
    // Blocks rendering of everything else (they're children of this)
    // Until we have an authUser...
    return null;
  }

  return (
    <SocketContext.Provider value={{ lobbySocket }}>
      {children}
      <SocketConnectionCheck lobbySocket={lobbySocket} />
      <SocketReconnectAfterBackground socket={lobbySocket} />
    </SocketContext.Provider>
  );
};

const useLobbySocket = () => {
  const context = useContext(SocketContext);
  if (!context) return null;
  return context.lobbySocket;
};

export { SocketProvider, useLobbySocket };
