let events = []; // string[]
let eventsToId = {}; // Record<string, number>

const getId = (eventName) => { // string
  if (
    !eventsToId[eventName] &&
    eventName !== "v3.templates" &&
    eventName !== "v3.authorize"
  ) {
    console.log(" *** UNKNOWN EVENT ID: " + event + " ***");
  }

  return eventsToId[eventName] || eventName;
}

const getName = (id) => { // number | string
  if (
    typeof id == "string" &&
    id !== "v3.templates" &&
    id !== "v3.authorize"
  ) {
    console.log(" *** UNKNOWN INCOMING EVENT ID: " + id + " ***");
  }

  return typeof id === "number" ? events[id] : id;
}

const setEvents = (evts) => { // string[]
  events = evts;
  eventsToId = {};

  for (let x = 0; x < events.length; x++) {
    eventsToId[events[x]] = x;
  }
}

module.exports = { getId, getName, setEvents };
