import { lazy } from "react";
import { Navigate, RouteObject } from "react-router-dom";

const ChatPage = lazy(() => import("pages/chat"));
const CodeOfConductPage = lazy(() => import("pages/code-of-conduct"));
const GroupChatPage = lazy(() => import("pages/group-chat"));
const HomePage = lazy(() => import("pages/home"));
const PrivacyPolicyPage = lazy(() => import("pages/privacy-policy"));
const TermsPage = lazy(() => import("pages/terms"));

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/chat",
    element: <ChatPage />,
  },
  {
    path: "/code-of-conduct",
    element: <CodeOfConductPage />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicyPage />,
  },
  {
    path: "/terms",
    element: <TermsPage />,
  },
  {
    path: "/group-chat",
    element: <GroupChatPage />,
  },
  { path: "*", element: <Navigate to="/" /> },
];
